import './styles.css';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ReactHtmlParser from "react-html-parser";

const CenterCard = ({ ...Center }) => {
    useEffect(() => {
        console.log(Center)
    })
    var prenom = Center.prenom.split(/\\/g)
    var nom = Center.nom.split(/\\/g)

    return (
        <div id="centers-card__container">
            <h2>{Center.nom_centre}</h2>
            <p class="centres_p">{
                ReactHtmlParser(
                    (Center.poste_coordinateur !== "" ? Center.poste_coordinateur + " : <br/>" : "") +
                    prenom.map((e, i) => (e + " " + nom[i] + "<br>")) +
                    Center.etablissement.replace(/\\/g, '<br/>') + "<br/>" +
                    Center.adresse.replace(/\\/g, '<br/>') + "<br />" +
                    Center.code_postale
                )
            }</p>
            {Center.adresse_mail != "" && Center.adresse_mail !== null? <p>Mail : <a href={"mailto:" + Center.adresse_mail}>{Center.adresse_mail}</a></p> : ""}
            <p>Tél : <a href={"tel:" + Center.numero.substring(0, 14).replace(/\./g, '-')}>{Center.numero}</a></p>
        </div>
    )
}

CenterCard.propTypes = {
    Centers: PropTypes.object.isRequired,
};

export default CenterCard;