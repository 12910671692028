import ImgHeart from '../../images/site/Icones/Heart.png';
import ImgPeople from '../../images/site/Icones/People.png';
import ImgPeopleHeart from '../../images/site/Icones/PeopleHeart.png';
import ImgBook from '../../images/site/Icones/Book.png';

export const contentInfoTwos = [
    {
        id: 1,
        title: "Faire un don",
        text: "En faisant un don, vous nous permettez de renforcer l’efficacité des actions que nous menons dans notre combat contre cette maladie. Votre soutien nous est précieux et nous en avons toujours autant besoin !",
        link: ImgHeart,
        navLink: 'association/dons',
    },
    {
        id: 2,
        title: "Devenir adhérent",
        text: "Pour être acteur et nous apporter votre soutien, vous pouvez devenir adhérent de l’association. Votre soutien en tant qu’adhérent constitue une contribution essentielle à notre action !",
        link: ImgPeople,
        navLink: 'association/dons',
    },
    {
        id: 3,
        title: "Témoignage",
        text: "Parce qu’il est primordial de ne pas rester seul face à la maladie, nous recueillons des témoignages de patients, familles et proches vivant au quotidien avec celle-ci. N’hésitez pas à nous contacter si, vous-aussi, vous souhaitez partager votre histoire.",
        link: ImgPeopleHeart,
        navLink: 'association/temoignages',
    },
    {
        id: 4,
        title: "Le journal de Fabry",
        text: "Le Petit Journal de Fabry est édité par l’association une fois par an. Il fait le point sur l’évolution de la maladie et sur les actions entreprises par l’association. Il est gratuit.",
        link: ImgBook, 
        navLink: 'actualites/journal',
    }
]