import Member1 from '../../images/site/membres/najya.png';
import Member2 from '../../images/site/membres/jeanmarie.png';
import Member3 from '../../images/site/membres/arielle.png';
import Member4 from '../../images/site/membres/natalie.png';
import Member5 from '../../images/site/membres/richard.png';
import Member6 from '../../images/site/membres/virginie.png';

import Member7 from '../../images/site/comite/olivier.jpg';
import Member8 from '../../images/site/comite/geraldine.jpg';
import Member9 from '../../images/site/comite/roselyne.jpg';
import Member10 from '../../images/site/comite/lucie.png';
import Member11 from '../../images/site/comite/boris.jpeg';
import Member12 from '../../images/site/comite/didier.png';
import Member13 from '../../images/site/comite/patricia.jpg';
import Member14 from '../../images/site/comite/soumeya.png';




export const memberList = [
    {
        id: 1,
        name: 'Najya Bedreddine',
        role: 'Présidente',
        img: Member1,
        description: "",
        roles: ['president', "co-president"]
    },
    
    {
        id: 2,
        name: 'Jean Marie Renaudin',
        role: 'Vice-Président',
        img: Member2,
        description: "",
        roles: ['president', "co-president"]
    },
    
    {
        id: 3,
        name: 'Docteur Roselyne GARNOTEL',
        role: 'Co-Présidente du comité scientifique',
        img: Member9,
        description: "CHU REIMS, \n Biochimiste",
        roles: ['comite']
    },
    
    {
        id: 4,
        name: 'Docteur Géraldine VIOT',
        role: 'Co-Présidente du comité scientifique',
        img: Member8,
        description: "Clinique de la Muette PARIS,\n Généticienne",
        roles: ['comite']
    },
    
    {
        id: 5,
        name: 'Natalie Triclin-Conseil',
        role: "Présidente d'Honneur Administrative",
        img: Member4,
        description: "",
        roles: ["president"]
    },
    
    {
        id: 6,
        name: 'Richard Pintat',
        role: 'Trésorier',
        img: Member5,
        description: "",
        roles: ['member']
    },
    
    {
        id: 7,
        name: 'Virginie Le Pera',
        role: 'Secrétaire',
        img: Member6,
        description: "",
        roles: ['member']
    },
    {
        id: 8,
        name: 'Arielle  Bachelery',
        role: 'Administratrice',
        img: Member3,
        description: "",
        roles: ['member']
    },

    {
        id: 9,
        name: 'Docteur Olivier LIDOVE',
        role: "Membre du comité scientifique",
        img: Member7,
        description: "Hôpital de la Croix Saint-Simon PARIS, Médecin interniste",
        roles: ['comite']
    },
    
    {
        id: 10,
        name: 'Lucie MAHE',
        role: 'Membre du comité scientifique',
        img: Member10,
        description: "Hôpital de la Croix Saint-Simon PARIS, Psychologue-Clinicienne",
        roles: ['comite']
    },
    {
        id: 11,
        name: 'Professeur Boris BIENVENU',
        role: 'Membre du comité scientifique',
        img: Member11,
        description: "Hôpital Saint-Joseph MARSEILLE, Médecin interniste",
        roles: ['comite']
    },
    {
        id: 12,
        name: 'Professeur Didier LACOMBE',
        role: 'Membre du comité scientifique',
        img: Member12,
        description: "CHU Pellegrin BORDEAUX,\n Généticien",
        roles: ['comite']
    },
    {
        id: 13,
        name: 'Professeur Patricia REANT',
        role: 'Membre du comité scientifique',
        img: Member13,
        description: "CHU BORDEAUX,\n Cardiologue",
        roles: ['comite']
    },
    {
        id: 14,
        name: 'Professeur Soumeya BEKRI',
        role: 'Membre du comité scientifique',
        img: Member14,
        description: "CHU ROUEN,\n Biochimiste",
        roles: ['comite']
    },
]