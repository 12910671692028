import alli from '../../images/site/logo-orga/Alliance-maladies-rares.png';
import euro from '../../images/site/logo-orga/eurordis.png';
import fabry from '../../images/site/logo-orga/FIN.png';
import orkid from '../../images/site/logo-orga/ORKiD.png';
import g2m from '../../images/site/logo-orga/G2M.png';
import cardiogen from '../../images/site/logo-orga/cardiogen.png';





export const orgasList = [
    {
        id: 1,
        name: 'Alliance Maladies Rares',
        img: alli,
        description:`Créée le 24 février 2000, l'Alliance Maladies Rares (association loi 1901) rassemble aujourd'hui plus de 200 associations de malades. Elle représente les 3 millions de personnes concernées par les maladies rares en France. Elle est, comme l'indique le préambule de ses statuts, « un collectif, un mouvement et un réseau... Elle est une union respectueuse des identités et de l'autonomie de chacun de ses membres auxquels elle ne se substitue pas ». L'Alliance est présente sur l'ensemble du territoire dans les régions de France à travers ses 12 délégations régionales. Elle fait partie de la Plateforme Maladies Rares, structure unique au monde réunissant six acteurs des maladies rares.
        La Présidente de l'Alliance Maladies Rares est Madame Nathalie TRICLIN-CONSEIL, Présidente d'Honneur-Administratrice de l'APMF.`,
        link:"https://www.alliance-maladies-rares.org/",
    },
    
    {
        id: 2,
        name: 'Eurordis',
        img: euro,
        description:"Eurordis est une alliance non gouvernementale d'associations de malades représentant 779 associations de patients atteints de maladies rares dans 69 pays. C'est le porte-parole des 30 millions de personnes touchées par les maladies rares en Europe.",
        link:"https://www.eurordis.org/f",
    },

    {
        id: 3,
        name: 'Fabry International Network',
        img: fabry,
        description: `Fabry International Network (FIN) est une organisation qui représente plus de 54 pays et 62 associations de patients dans le monde. L'adhésion est gratuite et ouverte à toute organisation nationale de patients dans laquelle les patients atteints de la maladie de Fabry sont représentés. Sa mission est d'être un réseau mondial et indépendant d'associations de patients atteints de la maladie de Fabry pour répondre aux objectifs de collaboration, communication et promotion des meilleures pratiques pour soutenir les personnes touchées par la maladie de Fabry`,
        link:"https://www.fabrynetwork.org/",
    },
    
    {
        id: 4,
        name: 'Filière Orkid',
        img: orkid,
        description:"Le réseau national des maladies rénales rares. La filière de santé ORKiD est une structure mise en place par le Ministère des Affaires Sociales et de la Santé qui anime et coordonne un réseau d'acteurs impliqués par la prise en charge des maladies rénales rares de l'enfant et de l'adulte. L'APMF participe à la journée ORKID une fois par an.",
        link:"https://www.filiereorkid.com/",
    },
    
    {
        id: 5,
        name: 'Filière G2M',
        img: g2m,
        description:"La Filière G2M a pour vocation d'améliorer la prise en charge des Patients atteints de Maladies Héréditaires du Métabolisme et de favoriser les interactions entre les différents acteurs au service du Patient. Elle s'articule pour cela autour de Centres de Référence, de Centres de Compétences, de laboratoires ainsi que d'Associations de Patients atteints de Maladies Héréditaires du Métabolisme. L'APMF participe aux réunions de la filière, elle a présenté l'étude FabRamSpec, la mallette FILIGANE (Education Thérapeutique du Patient sur la maladie de Fabry)... Cela représente en moyenne 2 à 3 réunions par an.",
        link:"http://www.filiere-g2m.fr",
    },
    
    {
        id: 6,
        name: 'Filière Cardiogen',
        img: cardiogen,
        description:"A l'issue d'un appel à projet de la Direction Générale de l'Offre de Soins, la filière de santé maladies rares Cardiogen a été labellisée en 2014. Elle regroupe les divers acteurs de la prise en charge des maladies cardiaques héréditaires. L'APMF participe aux réunions et cela représente en moyenne deux à trois réunions par an.",
        link:"https://www.filiere-cardiogen.fr",
    },
        
]