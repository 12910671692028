import './styles.css';
import Img_Takeda from "../../../../images/site/Partenaires/Takeda.png"
import Img_Sanofi_Genzyme from "../../../../images/site/Partenaires/Sanofi-Genzyme.png"
import Img_Amicus from "../../../../images/site/Partenaires/Amicus.png"



const PartenairesAPMF = () => {
    return (

        <div id="partenairesAPMF-page">
            
            <div className="row">
                <div className="col-md-12 text-center text_partenaires">
                    
                    <h1>Ils nous font confiance et nous soutiennent.</h1>
                    <h4 className="APMFPartenaires__subtitle"><strong>Merci à eux</strong></h4>
                    
                </div>
            </div>
            <div className="partenairesAPMF_contenaire">
                
                            <div className="Img_shire partenairesAPMF_subcontainer">
                                <a target="_blank" href="https://www.takeda.com/fr-fr/">
                                    <img src={Img_Takeda} alt="img" className="Img_takeda partenairesAPMF-images"></img>
                                </a>
                                <p>Takeda Pharmaceutical est la plus grande entreprise pharmaceutique japonaise</p>
                            </div>
                        

                        
                            <div className="Img_genzyme partenairesAPMF_subcontainer">
                           
                                <div className="div_genzyme"> 
                                    <a target="_blank" href="https://www.sanofi.com/fr/Votre-sante/soins-specialises">
                                        <img src={Img_Sanofi_Genzyme} alt="img" className="Img_genzyme partenairesAPMF-images"></img>
                                    </a>
                                </div>
                                <p>Genzyme est une entreprise de biotechnologie spécialisée dans le secteur des maladies rares ou orphelines</p>
                            </div>
                        

                        
                            <div className="Img_groupama partenairesAPMF_subcontainer ">
                                <a target="_blank" href="https://www.amicusrx.fr/">
                                    <img src={Img_Amicus} alt="img"  className="Img_amicus partenairesAPMF-images"></img>
                                </a>
                                <p>Amicus Therapeutics est une entreprise de biotechnologie développant des thérapies novatrices pour le traitement de maladies orphelines rares</p>
                            </div>
                
            </div>
        </div>
    )
}

export default PartenairesAPMF;